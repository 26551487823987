define([
    'santaPreviewComponents/components/fixedPositionContainerPreviewExtension',
    'santaPreviewComponents/components/WRichTextPreviewExtension'
], function (fixedPositionContainerPreviewExtension,
             WRichTextPreviewExtension) {
    'use strict';

    return {
        'wysiwyg.viewer.components.HeaderContainer': fixedPositionContainerPreviewExtension,
        'wysiwyg.viewer.components.FooterContainer': fixedPositionContainerPreviewExtension,
        'wysiwyg.viewer.components.WRichText': WRichTextPreviewExtension

    };
});
