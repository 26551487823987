define([
    'lodash',
    'santa-components'
], function (
    _,
    santaComponents
) {
    'use strict';

    function addMeasurer(dangerouslySetInnerHTML) {
        if (dangerouslySetInnerHTML && dangerouslySetInnerHTML.__html) {
            dangerouslySetInnerHTML.__html += '<div class="measurer" style="position: absolute"></div>';
        }
    }

    function shouldDisablePointerEvents(props) {
        return props.isResponsive && props.componentViewMode === 'editor';
    }

    const extend = baseClass => {
        class WRichTextPreviewExtension extends baseClass {
            transformRefData(refData) { // eslint-disable-line complexity
                if (this.props.shouldHideTextComponent) {
                    refData[''].style.visibility = 'hidden';
                }

                if (shouldDisablePointerEvents(this.props)) {
                    refData[''].style.pointerEvents = 'auto';
                }

                const {packed, minHeight} = this.props.compProp || {};
                if (packed || minHeight) {
                    return;
                }

                const textContainer = refData.richTextContainer || refData[''];
                const dangerouslySetInnerHTML = textContainer && textContainer.dangerouslySetInnerHTML;
                addMeasurer(dangerouslySetInnerHTML);

                return refData;
            }
        }
        WRichTextPreviewExtension.propTypes = _.defaults({
            componentViewMode: santaComponents.santaTypesDefinitions.RenderFlags.componentViewMode,
            shouldHideTextComponent: santaComponents.santaTypesDefinitions.RenderRealtimeConfig.shouldHideTextComponent
        }, WRichTextPreviewExtension.propTypes);
        return WRichTextPreviewExtension;
    };
    return extend;
});
