define([
    'lodash',
    'santa-components'
], function (_,
             santaComponents) {
    'use strict';

    const santaTypesDefinitions = santaComponents.santaTypesDefinitions;

    return baseClass => {
        class FixedPositionContainerPreviewExtension extends baseClass {
            getTransformedCssStates() {
                if (this.props.renderFixedPositionContainers) {
                    return this.state;
                }

                return _.omit(this.state, '$fixed'); //return transformed state
            }

            getRootPosition(style) {
                if (this.props.renderFixedPositionContainers) {
                    return style.position;
                }

                return this.props.isMeshLayoutMechanism ? '' : 'absolute';
            }
        }

        FixedPositionContainerPreviewExtension.propTypes = _.defaults({
            renderFixedPositionContainers: santaTypesDefinitions.RenderFlags.renderFixedPositionContainers.isRequired,
            isMeshLayoutMechanism: santaComponents.santaTypesDefinitions.Layout.isMeshLayoutMechanism
        }, baseClass.propTypes);

        FixedPositionContainerPreviewExtension.displayName = `${baseClass.displayName}Preview)`;

        return FixedPositionContainerPreviewExtension;
    };
});
